.header-layer {
  z-index: 950;
  position: fixed;
  top: 0px;
  left: 0;
  height: 80px;
  transition: 1s;
}

.header-layer.scroll {
  /* background-color: rgba(255, 255, 255, 0.7); */
  background-color: white;
  /* box-shadow: 3px 6px 15px #999999; */
  /* -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px); */
}

.header-layer .header-wrapper {
  height: 100%;
  display: flex;
  justify-content: space-between;
  margin-left: 0 !important;
  max-width: none;
}

@media only screen and (min-width: 1920px) {
  .header-layer .header-wrapper {
    padding: 0 7.142vw;
  }
}

.header-layer .header-wrapper .menu-logo {
  height: 60px;
  width: 60px;
  margin-top: 10px;
}

.header-layer.show .header-wrapper .menu-logo {
  position: relative;
  z-index: 1000;
}

.header-layer .header-wrapper #open-menu {
  height: 30px;
  margin-top: 25px;
}

.header-layer.show .header-wrapper #open-menu {
  display: none;
}

.header-layer .header-wrapper #hide-menu {
  width: 60px;
  height: 60px;
  border: white solid 3px;
  border-radius: 50%;
  margin-top: 10px;
  position: relative;
  z-index: 15000;
  display: none;
}

.header-layer.show .header-wrapper #hide-menu {
  display: block;
}

.header-layer .header-wrapper #hide-menu::before {
  content: '';
  position: absolute;
  width: 20px;
  height: 3px;
  background: white;
  border-radius: 4px;
  transform: translateY(25px) translateX(17px) rotate(45deg);
}

.header-layer .header-wrapper #hide-menu::after {
  content: '';
  position: absolute;
  width: 20px;
  height: 3px;
  background: white;
  border-radius: 4px;
  transform: translateY(25px) translateX(17px) rotate(-45deg);
}

.header-layer .header-wrapper .button-menu {
  cursor: pointer;
}

.header-layer .menu-layer {
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  width: 0;
  height: 100vh;
}

.header-layer .menu-layer .sub-menu-layer {
  width: 100%;
  height: 100vh;
  /* background-image: radial-gradient( ellipse 120% 113% at center 90%, #ff7a00 14%, transparent 58% ); */
}

.header-layer.show .menu-layer {
  background-color: black;
  width: 100%;
}

.header-layer .menu-layer .menu-box {
  width: calc(100% - 7.142vw);
  margin: 120px 0 0 7.142vw;
  height: calc(100vh - 120px);
  flex-direction: column;
  display: none;
}

.header-layer.show .menu-layer .menu-box {
  display: flex;
}

.header-layer .menu-layer .menu-box a:first-child {
  margin-top: 30px;
}

.header-layer .menu-layer .menu-box a:hover {
  color: white;
}

.header-layer .menu-layer .menu-box a .menu-item-underline {
  width: 0;
  height: 5px;
  border-radius: 2px;
  background-color: white;
  transition: 0.5s;
}

.header-layer .menu-layer .menu-box a:hover .menu-item-underline {
  width: 100%;
}

@media only screen and (max-width: 1024px) {
  .header-layer {
    margin: 0;
  }

  .header-layer .header-wrapper .menu-logo {
    height: 40px;
    width: 40px;
    margin-top: 20px;
  }

  .header-layer .header-wrapper #open-menu {
    height: 24px;
    margin-top: 28px;
  }

  .header-layer .header-wrapper #hide-menu {
    width: 40px;
    height: 40px;
    margin-top: 20px;
    border: white solid 2px;
  }

  .header-layer .header-wrapper #hide-menu::before {
    width: 14px;
    height: 2px;
    transform: translateY(16px) translateX(11px) rotate(45deg);
  }

  .header-layer .header-wrapper #hide-menu::after {
    width: 14px;
    height: 2px;
    transform: translateY(16px) translateX(11px) rotate(-45deg);
  }
}

@media only screen and (max-width: 1024px) {
  .header-layer .menu-layer .menu-box {
    margin-top: 80px;
    height: calc(100vh - 80px);
  }
}