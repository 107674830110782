.copyright-layer {
  margin-bottom: 30px;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
}

.copyright-layer .copyright-wrapper {
  margin: auto;
  display: flex;
  width: max-content;
  align-items: center;
}

.copyright-layer .copyright-wrapper .select-language {
  width: max-content;
}

.copyright-layer .copyright-wrapper p {
  margin: 0 0 0 20px;
}

@media only screen and (max-width: 480px) {
  .copyright-layer .copyright-wrapper {
    flex-direction: column;
  }

  .copyright-layer .copyright-wrapper p {
    margin: 10px 0 0 0;
  }
}