.footer {
  /* background-color: #011A4B; */
  flex-direction: column;
  align-items: center;
  /* color: white; */
  padding: 0 10% 60px 10%;
}

.footer .menu-footer {
  margin: 50px 0 30px 0;
  display: flex;
  justify-content: space-between;
}

.footer .menu-footer a {
  text-decoration: none;
  color: black;
}

.footer .menu-footer a:hover {
  text-decoration: underline;
}

.footer .menu-footer .footer-title {
  font-size: 29px;
  font-weight: 700;
  line-height: normal;
}

.footer .menu-footer .text-p-big {
  margin-top: 10px;
  font-size: 24px;
  font-weight: 400;
  line-height: normal;
  color: #a6a6a6 !important;
  text-decoration: none !important;
}

.footer .menu-footer .text-p-big img {
  width: 50px;
  height: 50px;
}

/* .footer .menu-footer .sub-content:last-child {
  margin-right: 10%;
} */

/* .footer .copyright {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
}

.footer .copyright p {
  margin: 0;
  width: 100%;
  text-align: center;
} */

@media only screen and (max-width: 1024px) {
  .footer .menu-footer .footer-title {
    font-size: 25px;
  }

  .footer .menu-footer .text-p-big {
    font-size: 20px;
  }

  .footer .menu-footer .text-p-big img {
    width: 40px;
    height: 40px;
  }
}

@media only screen and (max-width: 768px) {
  .footer {
    padding: 0 0 60px 0;
  }

  .footer .menu-footer {
    margin: 20px 0;
    flex-direction: column;
  }

  .footer .menu-footer .text-p-big {
    font-size: 16px;
  }

  .footer .menu-footer .text-p-big img {
    width: 30px;
    height: 30px;
  }

  .footer .menu-footer .sub-content {
    width: 100%;
    margin-top: 50px;
  }

  .footer .menu-footer .sub-content:first-child {
    margin-top: 0;
  }
}

@media only screen and (max-width: 480px) {
  .footer {
    padding: 0 0 100px 0;
  }

  .footer .menu-footer .footer-title {
    font-size: 22px;
  }

  .footer .menu-footer .text-p-big {
    font-size: 14px;
  }

  .footer .menu-footer .text-p-big img {
    width: 25px;
    height: 25px;
  }
}