.order-detail {
  height: calc(100% - 200px);
  margin: 125px 0 75px 0;
  align-items: center;
}

.order-detail .order-detail-wrapper {
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
}

.order-detail .order-detail-wrapper .info-box {
  width: 49%;
  min-height: 650px;
  padding: 0 30px 20px 30px;
  border-radius: 10px;
}

@media only screen and (max-width: 1460px) {
  .order-detail .order-detail-wrapper .info-box {
    min-height: 820px;
  }
}

@media screen and (max-width: 1170px) {
  .order-detail .order-detail-wrapper .info-box {
    min-height: 850px;
  }
}

@media screen and (max-width: 1090px) {
  .order-detail .order-detail-wrapper .info-box {
    min-height: 970px;
  }
}

.order-detail .order-detail-wrapper .order-info {
  border: 1px black solid;
  background-color: white;
}

.order-detail .order-detail-wrapper .order-info .info-title {
  display: flex;
  justify-content: space-between;
  padding: 20px 10px 15px 0;
  border-bottom: black 1px solid;
  margin-bottom: 10px;
  align-items: center;
}

.order-info .info-title h1 {
  margin: 0;
  color: black;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: -0.6px;
  word-spacing: -1px;
  font-size: 20px;
}

.order-info .info-title h2 {
  margin: 0;
  word-spacing: -2px;
  letter-spacing: -0.2px;
  text-align: right;
  font-weight: 700;
  font-size: 14px;
  color: black;
}

.order-info .info-detail {
  color: black;
}

.order-detail .info-detail .contract-item {
  border-bottom: black 1px solid;
  margin-bottom: 8px;
}

.order-info .info-detail .subtitle {
  font-size: 16px;
  font-weight: 700;
  line-height: normal;
  margin: 0;
}

.order-info .info-detail ul {
  margin-bottom: 4px;
}

.order-info .info-detail ul li {
  margin-top: 4px;
  font-size: 14px;
  word-wrap: break-word;
}

.order-detail .order-detail-wrapper .service-info {
  background-color: black;
  padding-bottom: 30px;
  height: 100%;
}

.order-detail .order-detail-wrapper .service-info h1 {
  color: #FAE12C;
  margin: 20px 0 5px 0;
  font-weight: 700;
  font-size: 20px;
  text-transform: uppercase;
}

.order-detail .order-detail-wrapper .service-info p,
.order-detail .order-detail-wrapper .service-info li {
  color: white;
  margin: 3px 0;
  line-height: 25px;
  font-size: 14px;
}

@media only screen and (max-width: 1460px) {
  .order-detail .order-detail-wrapper .order-info {
    width: 58%;
  }

  .order-detail .order-detail-wrapper .service-info {
    width: 40%;
  }
}

@media only screen and (max-width: 1024px) {
  .order-detail {
    height: calc(100% - 150px);
    margin: 100px 0 50px 0;
  }

  .order-detail .order-detail-wrapper .info-box {
    min-height: max-content;
    height: max-content;
    width: 100%;
    margin-bottom: 30px;
  }

  .order-detail .order-detail-wrapper {
    flex-direction: column;
  }

  .order-detail .order-detail-wrapper .order-info .info-title {
    flex-direction: column;
    align-items: flex-start;
    padding: 15px 0 10px 0;
  }

  .order-info .info-title h1 {
    font-size: 18px;
  }

  .order-info .info-title h2 {
    width: 100%;
    margin-top: 5px;
    text-align: left;
    font-size: 13px;
  }

  .order-detail .order-detail-wrapper .service-info h1 {
    font-size: 18px;
  }
}

@media only screen and (max-width: 768px) {
  .order-detail .order-detail-wrapper .order-info {
    padding: 0 20px 20px 20px;
  }

  .order-info .info-title h1 {
    font-size: 16px;
  }

  .order-info .info-title h2 {
    font-size: 14px;
  }

  .order-info .info-detail ul li {
    font-size: 16px;
    margin-top: 5px;
  }

  .order-detail .order-detail-wrapper .service-info h1 {
    font-size: 16px;
  }

  .order-detail .order-detail-wrapper .service-info p {
    font-size: 16px;
  }
}
